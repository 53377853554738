module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-79WYS8BJ32"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Monss - Digital Product Design Agency Calgary","short_name":"Monss","description":"Website for Monss - Digital Product Design Agency located in Calgary.","lang":"en","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"98057d2e1e228b5c07282211955cbd55"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
